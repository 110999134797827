import React, { Component } from "react";
import { Button, Form, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormGroup, Input } from "reactstrap";
import Auth from "../../../ServerCall/Auth";
import axiosApiInstance from "../../../ServerCall/Axios.Instance";
import { URL } from "../../../ServerCall/Config";
import {
  setAccessLevel,
  setAdminAccess,
  setCustomer,
  setSearchProduct,
} from "../../../redux/action/customerAction";
import LeveransdatumButton from "./LeveransdatumButton";
import LoginModal from "./LoginModal";
import SearchBar from "./SearchBar";

const acessToken = Auth.getToken();
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      cNo: "",
      searchString: "",
      custDetails: [],
      typing: false,
      typingTimeout: 0,
      show: false,
      access: "",
      gkAccess: "",
    };
    this.searchCancelRef = React.createRef();
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  async componentDidMount() {
    const access_level = Auth.getAccess_level();
    if (access_level !== undefined) {
      var accesslvl = access_level.find((obj) => obj === 3);
      let gkAdminAccessLevel;
      let isAdmin = false;
      access_level &&
        access_level.map((level) => {
          if (level === 4) {
            isAdmin = true;
            gkAdminAccessLevel = level;
          }
        });
      if (!isAdmin) gkAdminAccessLevel = access_level[0];
      this.setState({ access: accesslvl, gkAccess: gkAdminAccessLevel });
    }
    let isLoginMod = this.props.location.search;
    if (isLoginMod === "?login") {
      console.log("show login mod");
      this.handleShow();
    }
    var initial_url = window.location.href;
    var url = initial_url.split("?");
    if (url[1] === undefined) {
      await this.props.setSearchProduct("");
    }
    let userId = Auth.getUserId();
    if (userId !== undefined) {
      axiosApiInstance
        .get(`${URL}/users/getCustomer/${userId}`)
        .then(async (res) => {
          if (res.data.status === 200) {
            let customerDetails = res.data.data;
            await this.getCustomerAccessDD(customerDetails);
            customerDetails &&
              customerDetails.map((item) => {
                if (item.isDefault === 1) {
                  this.setState({ customerName: item.Name, cNo: item.CustNo });
                  this.props.setCustomer(item.CustNo);
                  if (item.access_level.length > 1) {
                    let myObj = item.access_level.find(
                      (obj) => obj.access_level === 4
                    );
                    this.props.setAdminAccess(myObj.access_level);
                  } else if (item.access_level[0].access_level === 4) {
                    this.props.setAdminAccess(
                      item.access_level[0].access_level
                    );
                    window.location.href = "/CreateUser";
                  }
                }
              });
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.log(err, "Error");
        });
    }
    if (this.props.searchName !== "" && this.props.searchName !== undefined) {
      this.searchCancelRef.current.style.display = "block";
    }
  }

  getCustomerAccessDD = (custDetails) => {
    let DDValue = [];
    custDetails &&
      custDetails.map((customer) => {
        customer.access_level &&
          customer.access_level.map((level) => {
            if (level.access_level === 3 || level.access_level === 1) {
              DDValue.push(customer);
            }
          });
      });
    this.setState({ custDetails: DDValue, customerName: DDValue[0].Name });
  };

  componentDidUpdate = () => {
    if (this.props.searchName !== "" && this.props.searchName !== undefined) {
      this.searchCancelRef.current.style.display = "block";
    }
  };

  handInput = (event) => {
    this.setState({ searchString: event.target.value });
    event.target.nextElementSibling.style.display = event.target.value
      ? "block"
      : "none";
  };

  handleChange = (event) => {
    var custId = event.target.value;
    localStorage.removeItem("scheduleDate");
    axiosApiInstance
      .post(`${URL}/users/setIsDefaultCustomer`, {
        UserNo: Auth.getUserId(),
        CustNo: custId,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            this.setState({ cNo: custId });
            let customerDetails = res.data.data;
            this.setState({ custDetails: customerDetails });
            customerDetails &&
              customerDetails.map((item) => {
                if (item.isDefault === 1) {
                  this.setState({ customerName: item.Name, cNo: item.CustNo });
                  this.props.setCustomer(item.CustNo);
                  if (item.access_level.length > 1) {
                    let myObj = item.access_level.find(
                      (obj) => obj.access_level === 4
                    );
                    this.props.setAdminAccess(myObj.access_level);
                  } else if (item.access_level[0].access_level === 4) {
                    this.props.setAdminAccess(
                      item.access_level[0].access_level
                    );
                    window.location.href = "/CreateUser";
                  }
                }
              });
          } else {
            console.log("Error");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeSearch = (e) => {
    const self = this;
    this.setState({ searchString: e.target.value });
    e.target.nextElementSibling.style.display = e.target.value
      ? "block"
      : "none";
    self.sendToParent(e.target.value);
    window.scrollTo(0, 0);
  };

  handleOnSearch = (e) => {
    const { searchString } = this.state;
    this.sendToParent(searchString);
    window.scrollTo(0, 0);
  };

  sendToParent = async (searchName) => {
    this.props.history.push({
      pathname: "/ProductOverview",
      search: `?${searchName}`,
      state: { searchDetail: searchName },
    });
    await this.props.setSearchProduct(searchName);
  };

  onClearHandler = async (e) => {
    await this.props.setSearchProduct("");
    this.sendToParent("");
    e.target.style.display = "none";
  };

  handleAdminAcc = () => {
    let isAdmin = true;
    localStorage.setItem("isAdmin", isAdmin);
  };

  render() {
    const { custDetails, cNo, customerName, show, access, gkAccess } =
      this.state;
    return (
        <div className="MenuWp">
          <Navbar
              collapseOnSelect
              expand="lg"
              bg="dark"
              variant="dark"
              className="wrapper"
          >
            {acessToken ? (
                <>
                  {custDetails !== undefined ? (
                      custDetails.length > 1 ? (
                          <div className="categoryNavWp">
                            <FormGroup>
                              <Input
                                  type="select"
                                  name="cNo"
                                  onChange={this.handleChange}
                                  value={cNo}
                                  id="exampleSelect"
                                  className="inputselectWpr"
                              >
                                {custDetails &&
                                    custDetails.map((select) => {
                                      return (
                                          <option
                                              value={select.CustNo}
                                              data-key={select.CustNo}
                                              className="optionWp"
                                          >
                                            {select.Name}
                                          </option>
                                      );
                                    })}
                              </Input>
                            </FormGroup>
                          </div>
                      ) : null
                  ) : null}
                  {custDetails !== undefined ? (
                      custDetails.length === 1 ? (
                          <Button className="btnAddShop btnUsername mr-3">
                            {customerName}
                          </Button>
                      ) : null
                  ) : null}
                </>
            ) : null}
            <Navbar.Collapse
                id="responsive-navbar-nav"
                style={{display: "block"}}
            >

              <Nav className="search-bar-select-date">
                <div className="searchbardiv">
                  <div class="clearable-input searchbar">
                    <button
                        type="submit"
                        class="searchButton"
                        onClick={this.handleOnSearch}
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <input
                        type="text"
                        name="searchString"
                        autoFocus={
                          this.props.searchName === "" ||
                          this.props.searchName === undefined
                              ? false
                              : true
                        }
                        autoComplete="off"
                        className="searchInput"
                        value={this.props.searchName}
                        placeholder="Sök på, varumärke, artikel, EAN"
                        onChange={(e) => this.handleChangeSearch(e)}
                    />
                    <span
                        data-clear-input
                        ref={this.searchCancelRef}
                        onClick={(e) => this.onClearHandler(e)}
                    >
                    &times;
                  </span>
                  </div>
                </div>
                <div className="verticalDivider"></div>
                <div className="select-date">
                  <LeveransdatumButton customerNo={cNo}/>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <div className="CartMobView">
            {acessToken ? (
                <Form inline>
                  <SearchBar customerNo={cNo}/>
                </Form>
            ) : null}
            {(acessToken && access === 4) ||
            gkAccess === 4 ||
            gkAccess === 0 ||
            gkAccess === 6 ? (
                <>
                  <Nav.Link
                      className="py-0"
                      eventKey={2}
                      onClick={this.handleAdminAcc}
                      href={gkAccess === 0 ? `/Dashboard` : `/CreateUser`}
                  >
                    Admin Panel
                  </Nav.Link>
                </>
            ) : null}
          </div>
          <LoginModal show={show} onHide={this.handleClose}/>
          {/*<div className="Notification-urgent">
            <p>Öppettider Under JUL OCH NYÅR<br/>24-25-26-31 December : 08:00 - 15:00<br/>
              1 Januari : Stängt - 6 Januari : 08:00 - 15:00</p>
          </div>*/}
        </div>

    );
  }
}

const mapStoreToProps = (state) => {
  return {
    cutomerNo: state.customer.cutomerNo,
    searchName: state.customer.searchName,
    adminAccess: state.customer.adminAccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (customerNo) => dispatch(setCustomer(customerNo)),
  setSearchProduct: (searchName) => dispatch(setSearchProduct(searchName)),
  setAccessLevel: (accesslvl) => dispatch(setAccessLevel(accesslvl)),
  setAdminAccess: (adminAccess) => dispatch(setAdminAccess(adminAccess)),
});

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(Menu));
